.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.active-menu-item {
  color: #ff0000; /* Change this to your desired text color */
  font-weight: 700;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white; /* Semi-transparent black overlay */
  z-index: 9999;
}

.overlay.active {
  display: block; /* Show the overlay when it has the 'active' class */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 200px; /* Adjust the max-width as needed */
}
/* Hide scrollbar for Firefox */
html {
  scrollbar-width: none;
}

/* Hide scrollbar for Webkit (Chrome, Safari) */
body::-webkit-scrollbar {
  display: none;
}

.content-wrapper blockquote {
  font-style: italic;
  margin: 20px 0;
  padding: 10px 20px;
  border-left: 5px solid #ccc;
  background: #f9f9f9;
}

.content-wrapper p {
  margin: 10px 0;
}

@media (min-width: 768px) {
  .scrolling-container {
    width: 100%;
    position: relative;
    white-space: nowrap;
    animation: scroll 20s linear infinite;
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-65%);
    }
  }
  .container-fluid {
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
    min-width: auto;
  }
  .paddingComponentRight {
    padding-right: 52px;
  }
  .paddingComponentLeft {
    padding-left: 52px;
  }
  .text-hero {
    color: var(--Gray-Second, #f8f8f8);
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .text-menu {
    /* color: #515a5f; */

    /* Paragraph/Small */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    letter-spacing: 0.5px;
  }
  .text-desc-footer {
    color: #fff;

    /* Paragraph/Small */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    letter-spacing: 0.5px;
    text-align: left;
  }
  .text-title-section {
    color: var(--Red, #f00);

    /* Heading/Heading 3 */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .text-title-services {
    color: var(--Black, #292d32);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .hero-background {
    /* background-image: url("./assets/heroImage.png");
    background-repeat: no-repeat; */
    /* background-position: center;
    background-size: cover; */
    height: 100vh;
    min-height: 25em;
    position: relative;
    width: 100%;
  }
  #heroVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .footer-part {
    width: "33%";
  }
  .publication-part {
    width: 572px;
    min-height: 393px;
  }
  .publication-img {
    width: 572px;
    height: 310px;
    flex-shrink: 0;
  }
  .publication-date {
    color: #515a5f;

    /* Paragraph/Small */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    letter-spacing: 0.5px;
  }
  .publication-title {
    overflow: hidden;
    color: var(--Black, #292d32);
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .publication-detail-title {
    color: var(--Black, #292d32);

    /* Heading/Heading 2 */
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .publication-detail-img {
    width: 100%;
  }
  .publication-detail-more-publication {
    color: var(--Black, #292d32);
    text-align: center;

    /* Heading/Heading 3 */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .publication-detail-more-publication-img {
    width: 376px;
    height: 204px;
    flex-shrink: 0;
  }
  .ic-whatsapp {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 999;
    right: 30px;
    /* top: 80vh; */
    bottom: 30px;
    background-color: white;
    cursor: pointer;
  }
  .pro-bono-title {
    color: var(--Red, #f00);
    text-align: center;

    /* Heading/Heading 3 */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .pro-bono-subtitle {
    color: var(--Black, #292d32);

    /* Paragraph/Medium */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .experts-section {
    padding-right: 52px;
    padding-left: 52px;
    background-color: #f4f4f4;
  }
  .experts-section-img {
    height: 423px;
  }
  .experts-section-img-each {
    width: 278px;
    height: 423px;
    flex-shrink: 0;
  }
  .experts-section-name-each {
    height: 419px;
    width: 321px;
  }
  .experts-section-detail-img-each {
    width: 278px;
    height: 423px;
    flex-shrink: 0;
  }
  .experts-section-detail-desc-div {
    width: 464px;
  }
  .experts-section-detail-name-each {
    height: 423px;
  }
  .experts-section-detail-img {
    height: auto;
  }
  .services-section-data {
    width: 560px;
  }
  .services-section-data-img {
    width: 560px;
    height: 290px;
    flex-shrink: 0;
  }
  .services-section-detail-bottom {
    padding-left: 148px;
    padding-right: 148px;
  }
  .services-sectiont-detail-paragraph-title {
    color: var(--Red, #f00);

    /* Heading/Heading 3 */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .text-hero-div {
    width: 33%;
    border-left: 1px solid #515a5f;
    border-right: 1px solid #515a5f;
    padding: 32px;
  }
  .hero-div {
    height: 100vh;
    top: 0;
    position: relative;
  }
  .about-text {
    width: 700px;
    /* height: 162px; */
    flex-shrink: 0;
  }
  .container-achievment-img {
    width: 221px;
    height: 172px;
  }
  .services-detail-img {
    height: 290px;
  }
  .image-service-scrolling {
    width: 376px;
    height: 251px;
  }
  .container-text-image-scrolling {
    width: 376px;
    word-wrap: break-word;
  }
  .more-article-container {
    max-width: 340px;
  }
}

@media (max-width: 768px) {
  .scrolling-container {
    width: 100%;
    position: relative;
    white-space: nowrap;
    animation: scroll 20s linear infinite;
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-400%);
    }
  }
  .paddingComponentRight {
    padding-right: 0px;
  }
  .paddingComponentLeft {
    padding-left: 0px;
  }
  .text-hero {
    color: var(--Gray-Second, #f8f8f8);
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .text-menu {
    /* color: #515a5f; */

    /* Paragraph/Small */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    letter-spacing: 0.5px;
  }
  .text-desc-footer {
    color: #fff;

    /* Paragraph/Small */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    letter-spacing: 0.5px;
    text-align: center;
  }
  .text-title-section {
    color: var(--Red, #f00);

    /* Heading/Heading 3 */
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .text-title-services {
    color: var(--Black, #292d32);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .hero-background {
    /* background-image: url("./assets/heroImage.png"); */

    /* background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    height: 100vh;
    min-height: 25em;
    position: relative;
    width: 100%;
    background-color: black;
  }
  #heroVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .footer-part {
    width: 100%;
    padding-right: 32px;
    padding-left: 32px;
  }
  .publication-part {
    width: 100%;
  }
  .publication-img {
    height: 178px;
  }
  .publication-date {
    color: var(--Gray, #515a5f);
    /* Paragraph/Extra Small */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .publication-title {
    overflow: hidden;
    color: var(--Black, #292d32);
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .publication-detail-title {
    color: var(--Black, #292d32);

    /* Heading/Heading 4 */
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .publication-detail-img {
    width: 100%;
    height: 212px;
  }
  .publication-detail-more-publication {
    color: var(--Black, #292d32);
    text-align: center;

    /* Heading/Heading 4 */
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .publication-detail-more-publication-img {
    width: 100%;
    height: 178px;
    flex-shrink: 0;
  }
  .ic-whatsapp {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 999;
    right: 16px;
    /* top: 80vh; */
    bottom: 30px;
    background-color: white;
    cursor: pointer;
  }
  .pro-bono-title {
    color: var(--Red, #f00);
    text-align: center;

    /* Heading/Heading 4 */
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .pro-bono-subtitle {
    color: var(--Black, #292d32);

    /* Paragraph/Medium */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .experts-section {
    background-color: #f4f4f4;
  }
  .experts-section-img {
    overflow: auto;
  }
  .experts-section-img-each {
    width: 100%;
    min-width: 258px;
    min-height: 337px;
    /* flex-shrink: 0; */
  }
  .experts-section-name-each {
    width: 100%;
    min-width: 258px;
    height: 423px;
    /* flex-shrink: 0; */
  }
  .experts-section-detail-img-each {
    width: 343px;
    height: 500px;
    flex-shrink: 0;
  }
  .experts-section-detail-name-each {
    height: 448px;
  }
  .experts-section-detail-img {
    height: auto;
  }
  .services-section-data {
    width: 100%;
  }
  .services-section-data-img {
    width: 100%;
    height: 178px;
    flex-shrink: 0;
  }
  .services-section-detail-bottom {
    padding-left: 0px;
    padding-right: 0px;
  }
  .services-sectiont-detail-paragraph-title {
    color: var(--Red, #f00);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .text-hero-div {
    width: 100%;
  }
  .hero-div {
    height: 100vh;
    top: 0;
    position: relative;
  }
  .navbar-logo {
    width: auto;
    height: auto;
    flex-shrink: 0;
  }
  .experts-section-detail-desc-div {
    width: 100%;
  }
  .about-text {
    width: 100%;
  }
  .container-achievment-img {
    width: 221px;
    height: 172px;
  }
  .services-detail-img {
    height: auto;
    max-height: 290px;
  }
  .image-service-scrolling {
    width: 231px;
    height: 181px;
  }
  .container-text-image-scrolling {
    width: 231px;
    word-wrap: break-word;
  }
  .more-article-container {
    max-width: 100%;
  }
}
